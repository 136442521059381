import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/seo";

import Fade from "react-reveal/Fade";

import MarkdownStringRenderer from "../components/MarkdownStringRenderer";

const PAGE_CONTENTS = {
  content: `
  Please read these terms and conditions carefully before using this site.

  Upper Canada Soap Company and its related, associated, affiliated, successor and subsidiary entities (from this point, collectively referred to as “Upper Canada Soap”) are pleased to provide this website (the “Site”) for your personal non-commercial use. By using this Site, you agree to the terms and conditions provided below including Upper Canada Soap’s Privacy Policy (collectively, “Terms and Conditions”). If you do not agree to the Terms and Conditions, please do not use this Site. In the event that any Upper Canada Soap website contains terms and conditions for the use of that site which conflict with or are inconsistent with the Terms and Conditions, those terms and conditions will prevail and govern to the extent of the conflict or inconsistency in connection with the use of that site.

  Upper Canada Soap reserves the right to update, revise or modify these Terms and Conditions at any time, without prior notice, by updating this posting. Your use of this Site following any such update, revision, or modification constitutes your agreement to follow and be bound by such revision, update or modification. We recommend you review these Terms and Conditions each time you use this Site.

  ### Ownership of Intellectual Property/Restrictions on Use of Materials

  The Site is owned and operated by Upper Canada Soap. Unless otherwise indicated the contents including without limitation, all logos, trade-marks, service marks, trade-dress, texts, graphics, images, and the collection, organization, arrangement and assembly of the contents of this Site (collectively, the “Content”) are the property of Upper Canada Soap and are protected, without limitation, pursuant to Canadian, U.S. and foreign intellectual property laws, including copyright, trademark and other laws and regulations.

  No Content from this Site, either in whole or in part, may be copied, reproduced, republished, uploaded, posted, transmitted, archived, modified, sold or distributed in any way, without Upper Canada Soap’s prior written consent except that you may download one copy of the Content on any single computer for your personal non-commercial use only, provided you keep intact all copyright and other proprietary notices. Modification of the Content or use of the Content for any other purpose is a violation of Upper Canada Soap’s copyright, trademark, and trade secret rights and others’ proprietary rights. The use of any Content on any other web site or networked computer environment for any purpose is strictly prohibited.

  In the event you download software from the Site, the software including any files, images incorporated in or generated by the software and data accompanying the Software (together, the “Software”) are non-exclusively licensed to you by Upper Canada Soap. Upper Canada Soap does not transfer title in and to the Software to you. As between you and Upper Canada Soap, Upper Canada Soap retains full and complete right, title, and interest in and to the Software and all intellectual property rights therein. You may not redistribute, sell, decompile, reverse-engineer or disassemble the Software.

  ### Use of Site and Site Material/ Acceptable Site Use

  #### **_General Rules._**

  Users may not use the Site in order to transmit, distribute, store or destroy the Content or any material: (a) in violation of any applicable law or regulation; (b) in a manner that will infringe the copyright, trademark, trade secret or other intellectual property rights of others or violate the privacy, publicity or other personal rights of others; or (c) that is defamatory, obscene, threatening, scandalous, inflammatory, pornographic, profane, abusive or hateful. Users may use the Site only for lawful purposes and in compliance with the Terms of Use.

  #### **_Web Site Security Rules._**
  Users are prohibited from violating or attempting to violate the security of the Site, including, without limitation: (a) accessing data not intended for such user or logging into a server or account which the user is not authorised to access; (b) attempting to probe, scan or test the vulnerability of a system or network or to breach security or authentication measures without proper authorisation; or (c) attempting to interfere with service to any user, host or network, including, without limitation, via means of submitting a virus to the Site, overloading, “flooding”, “spamming”, “mailbooming” or “crashing”. Violations of any system or network security may result in civil or criminal liability. Upper Canada Soap will investigate occurrences which may involve such violations and may involve and co-operate with law enforcement authorities in prosecuting users who are involved in such violations.

  ### Idea Submission/Use of Information

  Upper Canada Soap strives to avoid the possibility of future misunderstandings when Upper Canada Soap develops products, whether internally or by third parties, which might seem to others to be similar to their own creative ideas or materials. Therefore, Upper Canada Soap does not accept unsolicited ideas for new products, packaging, recipes, marketing, manufacturing, advertising, research, techniques, know-how, concepts, business operations and the like (collectively, the “Submissions”). Should you ignore this policy and submit an idea to Upper Canada Soap, you hereby grant to Upper Canada Soap the full, unconditional, unlimited, irrevocable, worldwide right and license to your Submissions as submitted, including the ability to use your Submissions (and any modifications or adaptations thereof) and in any manner and for any purpose whatsoever without any notice or compensation to you. Additionally, you waive any so called moral rights in such Submissions (if any), even if such Submission is altered or changed in a manner not agreeable to you.

  Upper Canada Soap welcomes your comments, but does not seek to solicit any confidential or proprietary ideas, suggestions, materials or other information (as noted above). By submitting information, you grant hereby to Upper Canada Soap a royalty-free, perpetual, irrevocable, worldwide license to publish such information, use it as part of its operations, and incorporate its concepts in Upper Canada Soap products without compensation to you.

  If you provide Upper Canada Soap with any information, including but not limited to feedback, data, questions, comments, suggestions or the like, such information shall not be deemed confidential. Upper Canada Soap will not be liable for any use or disclosure of such information. Upper Canada Soap will not have any obligation to keep such information confidential and will be free to reproduce, use, disclose and distribute the information to others without limitation. Upper Canada Soap will be free to use any ideas, concepts, know-how or techniques contained in such information for any purpose whatsoever, including but not limited to developing, manufacturing and marketing products which incorporate or otherwise rely upon such information.

  ### Links

  The Site may contain links to third party sites. These links are provided solely as a convenience to you and not as an endorsement by Upper Canada Soap. Upper Canada Soap is not responsible for the content of linked third-party sites and does not make any representations regarding the content or accuracy of materials on such third party sites. If you decide to access linked third party sites, you do so at your own risk.

  ### Disclaimer

  UPPER CANADA SOAP MAKES NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE OR ITS CONTENTS, WHICH ARE PROVIDED FOR USE “AS IS, WHERE IS” BASIS AND “AS AVAILABLE.” UPPER CANADA SOAP DISCLAIMS ALL REPRESENTATIONS, WARRANTIES, AND CONDITIONS EXPRESS OR IMPLIED, OR ARISING BY STATUTE, USAGE, CUSTOM OR OTHERWISE, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS SITE AND ANY WEB SITE WITH WHICH IT IS LINKED. UPPER CANADA SOAP DOES NOT WARRANT THE FUNCTIONS, INFORMATION OR LINKS CONTAINED ON THIS SITE OR THAT ITS CONTENTS WILL MEET YOUR REQUIREMENTS, THAT THIS SITE, OR ITS CONTENTS, ARE FIT FOR ANY PARTICULAR PURPOSE OR THAT THE OPERATION OF THIS SITE OR ITS CONTENTS, WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES, WORMS, TROJAN HORSES, CANCELBOTS OR OTHER HARMFUL COMPONENTS. UPPER CANADA SOAP DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE OR THE RESULTS OF THE USE OF THE MATERIALS ON THIS SITE IN TERMS OF THEIR CORRECTNESS, ACCURACY, RELIABILITY, OR OTHERWISE. YOU (AND NOT UPPER CANADA SOAP) ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING, REPAIR OR CORRECTION.

  ### Limitation of Liability

  YOU USE THIS SITE AND ITS CONTENT AT YOUR OWN RISK. UNDER NO CIRCUMSTANCES, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL UPPER CANADA SOAP OR THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, SHAREHOLDERS, AGENTS OR LICENSEES BE LIABLE FOR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, COMPENSATORY, DIRECT OR INDIRECT DAMAGES, LOSS OF DATA, INCOME OR PROFIT OR DAMAGE TO PROPERTY THAT RESULT FROM THE USE OF, OR THE INABILITY TO USE, THE SITE OR THE CONTENTS ON THE SITE, ‘EVEN IF UPPER CANADA SOAP OR AN UPPER CANADA SOAP AUTHORISED REPRESENTATIVE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL UPPER CANADA SOAP TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES AND CAUSES OF ACTION (WHETHER IN CONTRACT, OR NOT (INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE) OR OTHERWISE) EXCEED THE AMOUNT PAID BY YOU, IF ANY, FOR ACCESSING THIS SITE.

  ### Indemnity of User

  You agree to defend, indemnify, and hold harmless Upper Canada Soap, and each of its officers, directors, employees, parents, shareholders, licensees, assigns and agents, from and against any claims, actions or demands, including without limitation reasonable legal and accounting fees, alleging or resulting from your use of the Content contained on the Site or your breach of the terms of these Terms and Conditions. Upper Canada Soap shall provide notice to you promptly of any such claim, suit, or proceeding and may assist you, in its sole discretion, at your expense, in defending any such claim, suit or proceeding.

  ### Jurisdiction

  By accessing the Site, you agree that the laws of the United Kingdom will apply to all matters relating to your use of this Site, without regard to rules relating to conflict of laws. Upper Canada Soap makes no claims that the Contents may be lawfully viewed or downloaded outside of the United Kingdom. Access to the Contents may not be legal by certain persons or in certain countries. If you access the Site from outside of the United Kingdom, you do so at your own risk and are responsible for compliance with the laws of your jurisdiction. Any action relating to these Terms and Conditions must be brought in The United Kingdom and you irrevocably consent to the exclusive jurisdiction of the courts of London, UK.
  Relationships
  The relationship between Upper Canada Soap and you will be that of independent contractors, and neither of us nor any of our respective officers, agents or employees will be held or construed to be partners, joint ventures, fiduciaries, employees or agents of the other as a result of these Terms and Conditions or your use of the Site.

  ### Severability

  If any provision of these Terms and Conditions shall be unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and Conditions and shall not affect the validity and enforceability of any remaining provisions. This Agreement is the entire agreement between you and Upper Canada Soap relating to the subject matter herein.

  ### Entire Agreement

  These Terms and Conditions, together with those incorporated or referred to herein, constitute the entire agreement between us relating to the subject matter hereof, and supersede any prior understandings or agreements (whether electronic, oral or written) regarding the subject matter, and may not be amended or modified except by Upper Canada Soap as set forth above.
  `
};

class Terms extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg press aligncenter">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={"25%"}>
                  <h2>Terms of use</h2>
                </Fade>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Terms of use"
          description="Read our terms of use to understand what a user agrees to in order to use our website."
        />

        <div className="page-content pages-press">
          <div className="section-press">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10 content">
                  <MarkdownStringRenderer
                    markdownString={PAGE_CONTENTS.content}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default Terms;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
